(function ($) {
  $(window).load(function () {

    // owl-carousel
    $('.owl-carousel:not(.slider)').owlCarousel({
      items: 1,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
      dots: true,

      // drag
      mouseDrag: false
    })

    // slider
    $('.slider').owlCarousel({
      items: 1,
      margin: 15,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 3
        }
      }
    });

    // form-sent
    setTimeout(() => {

      var locationSearch = '' + document.location.search;
      if (
        (-1 === locationSearch.indexOf('form[sent]') && -1 === decodeURIComponent(locationSearch).indexOf('form[sent]'))
        || -1 === document.location.hash.indexOf('#form')
      ) {
        return;
      }

      var $headerHeight = $('.header');
      var $message = $('.form-success-message');

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $('html').scrollTop();
      var newScrollTop = $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $('html, body').animate({ scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30 });
    }, 500);

  });

  $(document).ready(function () {

    // toggle homepage banner on page load
    //if ($(window).width() > 1199) {
      //$('.home .eyecatcher .image-collection').remove();
    //} else {
      //$('.home .eyecatcher .vimeo-video').remove();
    //}

    // sticky
    require('../../vendor/w3media/framework/assets/js/V2/body-sticky-class');

    // fancybox
    $('a[data-fancybox]').fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close"
      ]
    });

    // scroll-to-content
    $('.js-3w-scroll-to-content').on('click', function (event) {
      event.preventDefault();
      var identifier = $(this).attr('data-target');
      var $element = $(identifier);

      if ($element) {
        $('html, body').animate({ scrollTop: $element.offset().top }, 500);
      }
    });

    // tommy-zeb
    $(document).on('change', '#f-tommy-zeb-begin-date', function () {
      $(this).parent().parent().find('label').css('display', 'none');
    });
    $(document).on('change', '#f-tommy-zeb-end-date', function () {
      $(this).parent().parent().find('label').css('display', 'none');
    });
    $(document).on('change', '.tommy-zeb-accommodation-type-choice > select', function () {
      $(this).parent().parent().parent().parent().find('h2').css('display', 'none');
    });

    function offset(el) {
      var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    $('.homepage-big-cards .grid-items > .item:nth-of-type(2n + 1)').each(function () {
      var $width = 'calc(100vw - ' + $(this).find('.card').offset().left + 'px)';
      $(this).append('<div class="waves-div"></div>');
      $(this).find('.waves-div').css('width', $width);
    });
  });
})(jQuery);
